<template>
  <div class="clearBox">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      :inline="true"
      label-position="right"
      size="mini"
    >
      <!-- <ayl-main title="基本信息" :isCollapse="false"> -->
      <div class="header">基本信息</div>
      <div class="p24px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属公司：">{{form.deviceInfoVOIOV.companyName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备号：">{{form.deviceInfoVOIOV.deviceName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备厂家：">
              <el-select
                v-model="form.deviceInfoVOIOV.deviceCompany"
                placeholder="未选择"
                disabled
              >
                <el-option
                  v-for="(item, idx) in $enums.deviceCompanyType.list"
                  :key="idx"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品型号：">{{form.deviceInfoVOIOV.deviceModel}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备类型：">
              <el-select
                v-model="form.deviceInfoVOIOV.deviceType"
                placeholder="未选择"
                disabled
              >
                <el-option
                  v-for="(item, idx) in $enums.Operator.list"
                  :key="idx"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应用对象：">
              <el-select
                v-model="form.deviceInfoVOIOV.objectType"
                collapse-tags
                placeholder="未选择"
                disabled
                @change="onChangeObjectType"
              >
                <el-option
                  v-for="(item, idx) in $enums_hw.objectType.list"
                  :key="idx"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="协议类型：">
              <el-tag style="margin-right:4px" type="info" effect="plain" v-for="(item,idx) in form.deviceInfoVOIOV.protocolType" :key="idx">{{item}}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属标段：">
              <el-cascader
                v-model="form.deviceInfoVOIOV.bidId"
                placeholder="未选择"
                :options="bidList"
                :show-all-levels="false"
                filterable
                disabled
                @change="hanldBidId"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <div v-if="form.deviceInfoVOIOV.deviceType==$enums.Operator.list[0].value">
          <el-divider></el-divider>
          <el-form-item label="摄像头类型：" class="clearMr">
            <el-select
              v-model="pipeNumber"
              multiple
              collapse-tags
              @change="onSelectCameraChange"
              placeholder="未选择"
              disabled
              clearable
            >
              <el-option
                v-for="(item, idx) in $enums.cameraType.list"
                :key="idx"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="aisle">
            <div v-for="(item, idx) in form.deviceAttachedInfoVOIOVs" :key="idx">
              <div class="aisle-form">
                <div class="aisle-title">{{item.pipeName}}</div>
                <el-form-item
                  label="设备类型："
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedType'"
                  :rules="{required: true, message: '请选择设备类型', trigger: 'change'}"
                >
                  <el-select v-model="item.attachedType" placeholder="未选择" disabled>
                    <el-option
                      v-for="(item, idx) in $enums.DeviceType.list"
                      :key="idx"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="使用状态："
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].status'"
                  :rules="{required: true, message: '请选择使用状态', trigger: 'change'}"
                >
                  <el-select v-model="item.status" placeholder="未选择" disabled>
                    <el-option
                      v-for="(item, idx) in $enums.UseType.list"
                      :key="idx"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="型号："
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedModel'"
                  :rules="{trigger: 'blur'}"
                >{{item.attachedModel}}</el-form-item>

                <el-form-item
                  label="生产厂家："
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedCompany'"
                  :rules="{trigger: 'blur'}"
                >{{item.attachedCompany}}</el-form-item>

                <el-form-item
                  label="安装位置："
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].installLocation'"
                  :rules="{trigger: 'blur',}"
                >{{item.installLocation}}</el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header">其他信息</div>
      <div class="p24px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="主服务器地址：">{{form.deviceInfoVOIOV.serverAddress}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主服务器端口：">{{form.deviceInfoVOIOV.serverPort}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备服务器地址：">{{form.deviceInfoVOIOV.alternateServerAddress}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="备服务器端口：">{{form.deviceInfoVOIOV.alternateServerPort}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="音频编码格式：">{{form.deviceInfoVOIOV.audioEncodeFormat}}</el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- </ayl-main> -->
    </el-form>
    <div class="tac mt40px">
      <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
//代表单个摄像头的json
const aisle = {
  pipeName: null,
  attachedType: null,
  status: null,
  physicalPipe: null,
  attachedModel: null,
  installLocation: null,
  attachedCompany: null
};

export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nav: [{ name: "设备详情" }],
      debug: 0,
      submitLoading: false,
      bidList: [],
      protocolTypeList: [],
      companyList: [],
      pipeNumber: [],
      form: {
        deviceInfoVOIOV: {},
        deviceAttachedInfoVOIOVs: []
      },
      rules: {}
    };
  },
  watch: {
    pipeNumber: {
      handler(newName, oldName) {
        this.val_ = this.$_.differenceWith(oldName, newName, this.$_.isEqual);

        setTimeout(() => {
          oldName.forEach((e, i) => {
            if (this.val_[0] === e) {
              this.num = i;
            }
          });
        }, 50);
      }
    }
  },
  methods: {
    onChangeObjectType(val) {
      this.log(val, typeof val);
      this.form.deviceInfoVOIOV.protocolType = []; //清除之前的选项
      if (val !== "")
        this.protocolTypeList =
          val === 1
            ? this.$enums_hw.protocolType.getListForPeople()
            : this.$enums_hw.protocolType.getListForCar();
      else this.protocolTypeList = [];
    },
    //获取所属标段
    hanldBidId(arr) {
      this.form.deviceInfoVOIOV.bidId = arr[1];
    },
    onClose() {
      this.$emit("onClose");
    },
    //获取摄像头信息
    onSelectCameraChange(val) {
      if (val.length === 0) {
        this.form.deviceAttachedInfoVOIOVs = [];
        return;
      }
      if (val.length > this.form.deviceAttachedInfoVOIOVs.length) {
        for (
          let i = 0;
          i < val.length - this.form.deviceAttachedInfoVOIOVs.length;
          i++
        ) {
          aisle.pipeName = val[this.form.deviceAttachedInfoVOIOVs.length];
          this.form.deviceAttachedInfoVOIOVs.push(this.$_.cloneDeep(aisle));
        }
      } else {
        setTimeout(() => {
          this.form.deviceAttachedInfoVOIOVs.splice(this.num, 1);
        }, 250);
      }
    },
    async checkCompany(val) {
      if (!val) {
        this.simCardNumberList = [];
        this.form.deviceInfoVOIOV.simCardNumber = null;
        return;
      }
      // this.form.deviceInfoVOIOV.simCardNumber = null
      const tenantIdData = this.companyList.filter(
        e => e.companyId === this.form.deviceInfoVOIOV.company
      );
      this.simCardNumberList = await this.$api.GetNotEnableSimNumber({
        tenantId: tenantIdData[0].tenantId
      });
      this.form.deviceInfoVOIOV.simCardNumber = this.simCardNumberList[0]
        ? this.simCardNumberList[0]
        : null;
    }
  },
  async mounted() {
    let self = this;
    this.$refs["form"].resetFields(); //防止el-select在多选模式的时候验证抽风
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
    this.companyList = await this.$api_hw.common_getTenantCompanyInfo({});
    
    this.pipeNumber = this.form.deviceAttachedInfoVOIOVs.map(e => e.pipeName);
    //详情数据
    let formData = await self.$api_hw.equipmentManagement_getDeviceInfo({
      deviceId: self.dataFromDialog.deviceId
    });
    self.form = _fixData(formData);
    
    this.form.deviceInfoVOIOV.companyName = this.companyList.label;
    this.form.deviceInfoVOIOV.company = this.companyList.value;
    /**
     * 把服务器端的Data 修剪一下以方便编辑
     */
    function _fixData(d) {
      // for (let key in d) {

      // }
      self.log(d);
      return d;
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  padding: 4px 20px
  .aisle-form
    border: 1px solid #eeeeee
    margin-bottom: 8px
    padding: 0px 32px 10px 32px
    .aisle-title
      width: 100%
      padding: 24px 0 10px 0
      font-size: 16px
/deep/ .el-input--mini
      width: 170px
.p24px
  padding-top: 24px
.header
  border-left: 2px solid #1ABC9C
  height: 16px
  padding-left: 10px
  font-size: 16px
</style>
